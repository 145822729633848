<template>
  <div class="verification">
    <div class="details_box">
      <div class="main_container">
        <div class="login">
          <div class="login_box">
            <div class="box_tit">{{$t('lang.管理员验证')}}</div>
            <div class="item">
              <span class="item_label">{{$t('lang.管理员账号')}}</span>
              <el-input v-model="account" :placeholder="$t('lang.请输入管理员账号')"></el-input>
            </div>
            <div class="item">
              <span class="item_label">{{$t('lang.管理员密码')}}</span>
              <el-input v-model="password" :placeholder="$t('lang.请输入管理员密码')"></el-input>
            </div>
            <el-button type="primary" @click="passwordSubmit()" :disabled="disabled">{{$t('lang.确定')}}</el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- <Footer></Footer> -->
  </div>
</template>
<script>
import Header from '../../components/Header/Header'
// import Footer from '../../components/Footer/Footer'
import { login } from '../../interface.js'
export default {
  name: 'verification',
  components: {
    Header,
    // Footer,
  },
  data() {
    return {
      password: '',
      account: '',
      disabled: false,
    }
  },
  methods: {
    passwordSubmit () {
      if(!this.account){
        this.$message.error(this.$t('lang.请输入管理员账号'));
        return false;
      }
      if(!this.password){
        this.$message.error(this.$t('lang.请输入管理员密码'));
        return false;
      }
      login(this.account,this.password,res=>{
        this.disabled = true;
        if(res.code==200){
            this.$router.push('/Login');
            this.disabled = false;
            localStorage.token = res.token;
        }else{
          this.$alert(this.$t('lang.' + res.msg), this.$t('lang.提示'), {
            confirmButtonText: this.$t('lang.确定'),
            type: 'error'
          })
          this.disabled = false;
        }
      })
    }
  },
  mounted () {
  }
}
</script>

<style lang="less">
@import '../../assets/styles/varibles.less';
.verification{
  .details_box{
    width: 100%;
    min-height: calc(100vh - 210px);
    overflow: hidden;
    margin: auto;
    .el-button--default{
      color: @grey[0];
    }
    .el-button--default:hover{
      border: 1px solid #DCDFE6;
      color: @grey[0];
      background-color: transparent;
    }
    .main_container{
      min-width: @minWidth;
      max-width: @maxWidth;
      margin: auto;
    }
    .login{
      min-height: calc(100vh - 210px);
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      .login_box{
        width: 600px;
        // height: 360px;
        margin: 0px auto;
        padding: 25px 34px 34px 34px;
        border: 1px solid #fff;
        box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.2);
        border-radius: 12px;
        .box_tit{
          font-size: @font22;
          color: @fontColor1;
          font-weight: @fontWeight[600];
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 35px;
          .tit{
            font-size: @font16;
            color: rgba(0,0,0,0.6);
            padding-bottom: 6px;
            cursor: pointer;
          }
          .tit.on{
            font-size: @font20;
            color: @fontColor1;
            position: relative;
          }
          .tit.on:before{
            position: absolute;
            display: table;
            content: '';
            bottom: -3px;
            width: 100%;
            height: 3px;
            border-radius: 100px;
            background: linear-gradient(135.23deg, rgba(252,16,184,1) -0.4%,rgba(99,81,226,1) 51.77%,rgba(25,231,215,1) 101.3%);
          }
        }
        .item{
          margin-bottom: 30px;
          .item_label{
            display: flex;
            font-size: @font16;
            font-weight: @fontWeight[500];
            color: @fontColor1;
            margin-bottom: 10px;
          }
          .el-input{
            display: flex;
            margin-bottom: 20px;
            flex: 1;
            font-size: @font16;
            input{
              height: 48px;
              line-height: 48px;
              border-radius: 6px;
            }
          }
          .el-input:nth-child(2){
            margin-bottom: 0;
          }
          .forget_pass{
            font-size: @font14;
            line-height: 20px;
            color: #1874FF;
            display: flex;
            justify-content: flex-end;
            text-decoration: underline;
            margin-top: 14px;
            margin-bottom: 23px;
          }
        }
        .el-button{
          width: 100%;
          font-size: @font16;
          height: 55px;
          border-radius: 6px;
        }
      }
    }
  }
}
</style>
